.soe-menu {

  .soe-menu-item {
    padding: 8px 5px;
    cursor: pointer;

    .soe-icon {
      font-size: 1.5em;
    }

    .soe-menu-item-name {
      font-family: RalewayLight;
      font-size: 0.8em;
      padding: 3px 0px;
    }

    a {
      text-align: center;
      color: $color_menu_main;

      &:hover {
        color: $color_menu_hover;
      }
    }

    .active{
      color: $color_menu_hover;
    }
  }
}