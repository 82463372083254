.cust-padding {
  padding-bottom: 20px;
}

.cust-pad-15 {
  padding: 15px;
}

.cust-alert {
  padding: 20px 0px;
  font-size: 1.3em;
}

.cust-navslide {
  padding: 20px 0px;
  font-size: 3em;
}

.cust-filter {
  left: 50%;
}

.cust-zindex-bg {
  z-index: 15;
}

.cust-zindex-win {
  z-index: 16;
}

// audio
.cust-audio-list {
  width: 200px;
}

.cust-audio-view {
  width: 100px;
}

// login
.cust-login {
  width: 100%;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #F7F7F7;
}

.cust-login-form {
  width: 300px;
}

// block data text
.cust-block-data {
  width: 100%;
  padding: 10px;
  margin: 3px 0px;

  &.link {
    cursor: pointer;
  }
  &.default {
    background: $bg_content;
  }
  &.active {
    background: $color_bottom_danger_light;
  }
}

// link
.cust-link {
  color: $color_bottom_danger_main;
}

.cust-link:hover {
  color: $color_bottom_danger_hover;
}

// block for graph
.graph-item {
  cursor: move;
  background: #fff;
  border: 1px solid $color_bottom_warning_main;
  padding: 5px 10px;
  font-family: RalewayRegular;
  color: #73879C;
  font-size: 0.8em;
  width: 250px;
  position: absolute;

  .graph-item-title {
    font-family: RalewayMedium;
    padding: 3px 0px;
    width: 100%;
    text-align: center;
  }

  .graph-text{
    font-family: RalewayLight;
    color: $color_bottom_primary_main;
    font-size: 1em;
    margin: 3px 0px;
    padding: 5px;
    background: $bg_content;
    border: 1px solid $color_bottom_primary_main;
  }
}

// setting
.cust-tab-set{
  cursor: pointer;
  padding: 5px;
  margin: 0px 3px;

  &:hover{
    background: $color_bottom_success_hover;
    color: #fff;
  }

  &.active{
    background: $color_bottom_success_hover;
    color: #fff;
  }
}

// loader
.soe-loader-gb{
  z-index: 100;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: url("/img/loader.gif") center center no-repeat rgba(0, 0, 0, 0.1);
}

// chart call
.soe-coll-main{
  fill: $color_bottom_primary_main;
}
.soe-coll-full{
  fill: $color_text;
}