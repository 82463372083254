.soe-block {
  background: #fff;
  border: 1px solid $color_border_block;
  padding: 15px;
  margin: 10px;
  font-family: RalewayRegular;
  color: $color_text;
  font-size: 1em;
}

.soe-text {
  font-family: RalewayLight;
  font-size: 1em;
  padding: 5px;

  &.default{
    color: $color_text;
  }
  &.primary{
    color: $color_bottom_primary_main;
  }
  &.success{
    color: $color_bottom_success_main;
  }
  &.warning{
    color: $color_bottom_warning_main;
  }
  &.danger{
    color: $color_bottom_danger_main;
  }
  &.dark{
    color: $color_bottom_dark_main;
  }
}

.soe-title {
  font-family: RalewayBold;
  font-size: 1.2em;
  color: $color-text;
  padding: 5px 0px;

  &.left {
    text-align: left;
  }
  &.center {
    text-align: center;
  }
  &.right {
    text-align: right;
  }
}

.soe-label{
  font-family: RalewaySemiBold;
  font-size: 1em;
  color: $color-text;
  padding: 3px 0px;
  width: 100%;

  &.left {
    text-align: left;
  }
  &.center {
    text-align: center;
  }
  &.right {
    text-align: right;
  }
}

.soe-flex {
  display: flex;
  position: relative;

  &.left {
    justify-content: flex-start;
  }
  &.center {
    justify-content: center;
  }
  &.right {
    justify-content: flex-end;
  }
  &.middle{
    align-items: center;
  }
}

.soe-block-fix{
  background: #fff;
  border: 1px solid $color_border_block;
  padding: 15px;
  margin: 10px;
  font-family: RalewayRegular;
  color: $color_text;
  font-size: 1em;

  &.w200{
    width: 200px;
  }
  &.w300{
    width: 300px;
  }
}