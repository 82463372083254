input[type=text],
input[type=password],
input[type=tel],
input[type=date] {
  width: 100%;
  border: 1px solid $color_border_block;
  background: #fff;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  padding: 7px 15px;
  outline: none;
  font-size: 1em;
  font-family: RalewayRegular;
  color: $color_text;
  margin: 5px 0px;

  &:focus {
    border: 1px solid $color_form_active;
  }
}

textarea {
  width: 100%;
  border: 1px solid $color_border_block;
  background: #fff;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  padding: 10px 15px;
  outline: none;
  font-size: 1em;
  font-family: RalewayRegular;
  color: $color_text;
  resize: vertical;
  margin: 5px 0px;
  height: 100px;

  &:focus {
    border: 1px solid $color_form_active;
  }
}

select {
  width: 100%;
  border: 1px solid $color_border_block;
  background: #fff;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  padding: 7px 15px;
  outline: none;
  font-size: 1em;
  font-family: RalewayRegular;
  color: $color_text;
  margin: 5px 0px;

  &:focus {
    border: 1px solid $color_form_active;
  }
}

input[type=checkbox] {
  display: none;
}

.checkbox-label {
  display: inline-block;
  width: 22px;
  height: 22px;
  cursor: pointer;
  overflow: hidden;
}

.checkbox-label:before {
  content: '\56';
  font-family: ElegantIcons;
  color: $color-text;
  display: inline-block;
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 1.2em;
}

input[type=checkbox]:checked + label.checkbox-label:before {
  content: "\5a";
  font-family: ElegantIcons;
  color: $color-bottom_success_main;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}

input[type=radio] {
  display: none;
}

.radio-label {
  display: inline-block;
  width: 22px;
  height: 22px;
  cursor: pointer;
  overflow: hidden;
}

.radio-label:before {
  content: '\5b';
  font-family: ElegantIcons;
  color: $color-text;
  display: inline-block;
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 1.3em;
}

input[type=radio]:checked + label.radio-label:before {
  content: "\5c";
  font-family: ElegantIcons;
  color: $color-bottom_success_main;
  display: flex;
  align-items: center;
  justify-content: center;
}

input[type=file] {
  display: none;
}
