table {
  width: 100%;
  border-spacing: 0;
  color: $color_text;

  thead {
    font-family: RalewaySemiBold;
    font-size: 1em;
    font-weight: normal;

    tr {

      th {
        border-bottom: 2px solid $color_border_table;
        text-align: center;
        padding: 5px;
        font-weight: normal;
      }
    }
  }

  tbody {
    font-family: RalewayRegular;
    font-size: 1em;

    tr {

      &:hover{
        background: rgba(38,185,154,.07);
      }

      &.click{
        cursor: pointer;
      }
      &.use{
        background: $color_bottom_warning_light;
      }

      td {
        border-bottom: 1px solid $color_border_table;
        text-align: center;
        padding: 3px;

        &.left {
          text-align: left;
        }
        &.right{
          text-align: right;
        }
      }
    }
    tr:last-child {

      td {
        padding: 3px;
        text-align: center;
        border-bottom: none;

        &.left {
          text-align: left;
        }
      }
    }
  }

}
