[v-cloak] {
  display: none
}

// Colors
$bg_menu: #2A3F54;
$bg_top: #EDEDED;
$bg_content: #F7F7F7;

$color_menu_main: #ECF0F1;
$color_menu_hover: #1ABB9C;

$color_text: #73879C;
$color_icon: #C5C7CB;

$color_border_block: #E6E9ED;
$color_border_table: #DDDDDD;

$color_bottom_default_main: #FFFFFF;
$color_bottom_default_hover: #e6e6e6;
$color_bottom_default_border: #adadad;

$color_bottom_primary_main: #337ab7;
$color_bottom_primary_hover: #286090;
$color_bottom_primary_border: #204d74;

$color_bottom_success_main: #26B99A;
$color_bottom_success_hover: #169F85;
$color_bottom_success_border: #398439;

$color_bottom_info_main: #5bc0de;
$color_bottom_info_hover: #31b0d5;
$color_bottom_info_border: #269abc;

$color_bottom_warning_main: #f0ad4e;
$color_bottom_warning_hover: #ec971f;
$color_bottom_warning_border: #d58512;
$color_bottom_warning_light: #ffffe6;

$color_bottom_danger_main: #d9534f;
$color_bottom_danger_hover: #c9302c;
$color_bottom_danger_border: #ac2925;
$color_bottom_danger_light: #faebea;

$color_bottom_dark_main: #4B5F71;
$color_bottom_dark_hover: #394D5F;
$color_bottom_dark_border: #394D5F;

$color_form_active: #66afe9;

// Main class
.soe-icon {
  font-family: ElegantIcons;
}

// elements
a {
  text-decoration: none;
  color: inherit;
  width: 100%;
}

// Main blocks
.soe-wrap {
  display: flex;
  min-height: 100%;
}

// Wrap
.soe-main {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

// Left Section
.soe-left {
  max-width: 100px;
  width: 100%;
  background: $bg_menu;
}

// Top Section
.soe-top {
  width: 100%;
  height: 50px;
  background: $bg_top;
  padding: 0px 20px;
  display: flex;
}

// Content Section
.soe-content {
  width: 100%;
  flex: 1 1 auto;
  background: $bg_content;
  padding: 20px;
}

// Footer Section
.soe-footer {
  width: 100%;
  height: 50px;
  background: $bg_top;
  padding: 0px 20px;
  display: flex;
}

// Import fw files
@import "content";
@import "elemets";
@import "form";
@import "grid12";
@import "popup";
@import "table";
@import "menu";