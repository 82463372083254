.grid-block {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  position: relative;

  &.middle {
    align-items: center;
  }
  &.top {
    align-items: flex-start;
  }
  &.foot {
    align-items: flex-end;
  }
  &.left {
    justify-content: flex-start;
  }
  &.center {
    justify-content: center;
  }
  &.right {
    justify-content: flex-end;
  }
}

$wight: 100%;
$colum: $wight/12;

.grid-1 {
  width: $colum*1;
  word-wrap: break-word;

  &.center {
    text-align: center;
  }
  &.left {
    text-align: left;
  }
  &.right {
    text-align: right;
  }
}

.grid-2 {
  width: $colum*2;
  word-wrap: break-word;

  &.center {
    text-align: center;
  }
  &.left {
    text-align: left;
  }
  &.right {
    text-align: right;
  }
}

.grid-3 {
  width: $colum*3;
  word-wrap: break-word;

  &.center {
    text-align: center;
  }
  &.left {
    text-align: left;
  }
  &.right {
    text-align: right;
  }
}

.grid-4 {
  width: $colum*4;
  word-wrap: break-word;

  &.center {
    text-align: center;
  }
  &.left {
    text-align: left;
  }
  &.right {
    text-align: right;
  }
}

.grid-5 {
  width: $colum*5;
  word-wrap: break-word;

  &.center {
    text-align: center;
  }
  &.left {
    text-align: left;
  }
  &.right {
    text-align: right;
  }
}

.grid-6 {
  width: $colum*6;
  word-wrap: break-word;

  &.center {
    text-align: center;
  }
  &.left {
    text-align: left;
  }
  &.right {
    text-align: right;
  }
}

.grid-7 {
  width: $colum*7;
  word-wrap: break-word;

  &.center {
    text-align: center;
  }
  &.left {
    text-align: left;
  }
  &.right {
    text-align: right;
  }
}

.grid-8 {
  width: $colum*8;
  word-wrap: break-word;

  &.center {
    text-align: center;
  }
  &.left {
    text-align: left;
  }
  &.right {
    text-align: right;
  }
}

.grid-9 {
  width: $colum*9;
  word-wrap: break-word;

  &.center {
    text-align: center;
  }
  &.left {
    text-align: left;
  }
  &.right {
    text-align: right;
  }
}

.grid-10 {
  width: $colum*10;
  word-wrap: break-word;

  &.center {
    text-align: center;
  }
  &.left {
    text-align: left;
  }
  &.right {
    text-align: right;
  }
}

.grid-11 {
  width: $colum*11;
  word-wrap: break-word;

  &.center {
    text-align: center;
  }
  &.left {
    text-align: left;
  }
  &.right {
    text-align: right;
  }
}

.grid-12 {
  width: $colum*12;
  word-wrap: break-word;

  &.center {
    text-align: center;
  }
  &.left {
    text-align: left;
  }
  &.right {
    text-align: right;
  }
}