@font-face {
  font-family: 'ElegantIcons';
  src: url('ElegantIcons.ttf') format('truetype')
}
@font-face {
  font-family: 'RalewayBold';
  src: url('Raleway-Bold.ttf') format('truetype')
}

@font-face {
  font-family: 'RalewayLight';
  src: url('Raleway-Light.ttf') format('truetype')
}

@font-face {
  font-family: 'RalewayMedium';
  src: url('Raleway-Medium.ttf') format('truetype')
}

@font-face {
  font-family: 'RalewayRegular';
  src: url('Raleway-Regular.ttf') format('truetype')
}

@font-face {
  font-family: 'RalewaySemiBold';
  src: url('Raleway-SemiBold.ttf') format('truetype')
}

@font-face {
  font-family: 'RalewayThin';
  src: url('Raleway-Thin.ttf') format('truetype')
}
