.soe-icon-control {
  font-size: 1.1em;
  padding: 0px 5px;
  cursor: pointer;

  &.default {
    color: $color_bottom_default_main;

    &:hover {
      color: $color_bottom_default_hover;
    }
  }
  &.primary {
    color: $color_bottom_primary_main;

    &:hover {
      color: $color_bottom_primary_hover;
    }
  }
  &.success {
    color: $color_bottom_success_main;

    &:hover {
      color: $color_bottom_success_hover;
    }
  }
  .info {
    color: $color_bottom_info_main;

    &:hover {
      color: $color_bottom_info_hover;
    }
  }
  &.warning {
    color: $color_bottom_warning_main;

    &:hover {
      color: $color_bottom_warning_hover;
    }
  }
  &.danger {
    color: $color_bottom_danger_main;

    &:hover {
      color: $color_bottom_danger_hover;
    }
  }
  &.dark {
    color: $color_bottom_dark_main;

    &:hover {
      color: $color_bottom_dark_hover;
    }
  }
}

.soe-button {
  border: none;
  padding: 5px 10px;
  background: none;
  cursor: pointer;
  margin: 5px;
  outline: none;
  font-family: RalewayLight;
  font-size: 1em;
  display: block;
  text-align: center;

  &.default {
    border: 1px solid $color_bottom_default_border;
    background: #fff;
    color: $color_text;

    &:hover {
      background: $color_bottom_default_hover;
    }
  }

  &.primary {
    border: 1px solid $color_bottom_primary_border;
    background: $color_bottom_primary_main;
    color: $color_menu_main;

    &:hover {
      background: $color_bottom_primary_hover;
    }
  }

  &.success {
    border: 1px solid $color_bottom_success_border;
    background: $color_bottom_success_main;
    color: $color_menu_main;

    &:hover {
      background: $color_bottom_success_hover;
    }
  }

  &.warning {
    border: 1px solid $color_bottom_warning_border;
    background: $color_bottom_warning_main;
    color: $color_menu_main;

    &:hover {
      background: $color_bottom_warning_hover;
    }
  }

  &.danger {
    border: 1px solid $color_bottom_danger_border;
    background: $color_bottom_danger_main;
    color: $color_menu_main;

    &:hover {
      background: $color_bottom_danger_border;
    }
  }

  &.dark {
    border: 1px solid $color_bottom_dark_hover;
    background: $color_bottom_dark_main;
    color: $color_menu_main;

    &:hover {
      background: $color_bottom_dark_hover;
    }
  }
}

.soe-alert {
  font-size: 1.1em;
  padding: 0px 5px;
  font-family: RalewaySemiBold;

  &.default {
    color: $color_bottom_default_main;

    &:hover {
      color: $color_bottom_default_hover;
    }
  }
  &.primary {
    color: $color_bottom_primary_main;

    &:hover {
      color: $color_bottom_primary_hover;
    }
  }
  &.success {
    color: $color_bottom_success_main;

    &:hover {
      color: $color_bottom_success_hover;
    }
  }
  .info {
    color: $color_bottom_info_main;

    &:hover {
      color: $color_bottom_info_hover;
    }
  }
  &.warning {
    color: $color_bottom_warning_main;

    &:hover {
      color: $color_bottom_warning_hover;
    }
  }
  &.danger {
    color: $color_bottom_danger_main;

    &:hover {
      color: $color_bottom_danger_hover;
    }
  }
  &.dark {
    color: $color_bottom_dark_main;

    &:hover {
      color: $color_bottom_dark_hover;
    }
  }
}

.soe-data-title-block{
  position: relative;
}

span[data-title] {
  position:relative;
}
span[data-title]:before {
  position: absolute;
  left: 0;
  top: -40px;
  background-color: #f2f2f2;
  color: $color_text;
  height: 30px;
  line-height: 30px;
  border-radius: 5px;
  padding: 0 15px;
  content: attr(data-title);
  white-space: nowrap;
  display: none;
  font-family: RalewayRegular;
  font-size: 14px;
  /*border: 1px solid $color_border_block;*/
  z-index: 1;
}
span[data-title]:after {
  position: absolute;
  left: 15px;
  top: -10px;
  border-top: 7px solid #f2f2f2;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  content: "";
  display: none;
}
span[data-title]:hover:after, span[data-title]:hover:before {
  display: block;
}