.soe-popup-gb {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  background: rgba(0, 0, 0, 0.1);
}

.soe-popup {
  position: fixed;
  top: 15%;
  left: 50%;
  display: inline-block;
  padding: 20px;
  background: #fff;
  border: 1px solid $color_border_block;
  z-index: 10;

  &.w10 {
    width: 10%;
    margin-left: -5%;
  }
  &.w20 {
    width: 20%;
    margin-left: -10%;
  }
  &.w30 {
    width: 30%;
    margin-left: -15%;
  }
  &.w40 {
    width: 40%;
    margin-left: -20%;
  }
  &.w50 {
    width: 50%;
    margin-left: -25%;
  }

  .soe-popup-close {
    position: absolute;
    right: 0;
    top: 0;
    padding: 3px 5px;
  }
}

.soe-popup-filter {
  position: absolute;
  top: 40px;
  display: inline-block;
  padding: 10px 15px;
  background: #fff;
  border: 1px solid $color_border_block;
  z-index: 10;

  &.w100 {
    width: 100px;
    margin-left: -50px;
  }
  &.w150 {
    width: 150px;
    margin-left: -75px;
  }
  &.w200 {
    width: 200px;
    margin-left: -100px;
  }
  &.w300 {
    width: 300px;
    margin-left: -150px;
  }
  &.wp20{
    width: 20%;
    margin-left: -10%;
  }

  &.top {

    &:after, &:before {
      bottom: 100%;
      left: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }
    &:after {
      border-color: rgba(136, 183, 213, 0);
      border-bottom-color: #fff;
      border-width: 12px;
      margin-left: -12px;
    }
    &:before {
      border-color: rgba(194, 225, 245, 0);
      border-bottom-color: $color_border_block;
      border-width: 14px;
      margin-left: -14px;
    }
  }

  &.foot {

    &:after, &:before {
      top: 100%;
      left: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }
    &:after {
      border-color: rgba(136, 183, 213, 0);
      border-top-color: #fff;
      border-width: 12px;
      margin-left: -12px;
    }
    &:before {
      border-color: rgba(194, 225, 245, 0);
      border-top-color: $color_border_block;
      border-width: 14px;
      margin-left: -14px;
    }
  }
}

.soe-popup-wrap{
  max-height: 400px;
  height: 100%;
  overflow: auto;
}